




import { Component } from 'vue-property-decorator';
import { ApexOptions } from 'apexcharts';
import ChartBase from '../ChartBase';
import KpiRadialChartData from './modelsKpiRadial';

@Component
export default class ChartBar extends ChartBase {
  declare chartData: KpiRadialChartData;

  options: ApexOptions = {};

  series: any | null = null;

  onInitOrUpdate(): void {
    console.log('init or update');
    this.options = {
      chart: {
        id: '',
      },
    };

    if (!this.chartData.valueField || !this.chartData.targetField) return;

    this.series = this.queryResult
      // eslint-disable-next-line
      .map((x: any) => Math.round(x[this.chartData.valueField] / x[this.chartData.targetField] * 10000) / 100);

    this.options = {
      ...this.options,
      labels: this.queryResult.map((x: any) => `${x[this.chartData.valueField]} / ${x[this.chartData.targetField]}`),
      colors: this.queryResult.map((x: any) => (x[this.chartData.valueField] >= x[this.chartData.targetField] ? '#00a86f' : '#008ffb')),
    };
  }
}
